/* src/assets/css/global.css */

/* Rajdhani Light - font-weight: 300 */
@font-face {
  font-family: 'Rajdhani';
  src: url('../fonts/Rajdhani/Rajdhani-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Rajdhani Regular - font-weight: 400 */
@font-face {
  font-family: 'Rajdhani';
  src: url('../fonts/Rajdhani/Rajdhani-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Rajdhani Medium - font-weight: 500 */
@font-face {
  font-family: 'Rajdhani';
  src: url('../fonts/Rajdhani/Rajdhani-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Rajdhani SemiBold - font-weight: 600 */
@font-face {
  font-family: 'Rajdhani';
  src: url('../fonts/Rajdhani/Rajdhani-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* Rajdhani Bold - font-weight: 700 */
@font-face {
  font-family: 'Rajdhani';
  src: url('../fonts/Rajdhani/Rajdhani-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body{
font-family: 'Rajdhani', sans-serif;
color: #ffffff;
}
ul{
list-style:none;
padding:0;
 }
h1,h2,h3,h4,h5,h6{
font-weight:bold; 
 }

p{
color: #f4f4f4;
font-size:18px; 
 }
a{
text-decoration:none;
color:#fff;
 }
a:hover{
text-decoration:none;
background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
 }
a:focus{
text-decoration:none;
background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
 }

.button{
background: linear-gradient(to right,  #662D8C , #ED1E79);
color:#fff;
padding:10px 20px;
display: inline-block;
transition: 0.3s;
border-radius: 25px;
 }
.button:hover{
background:#fff;
color:#de1002;
 }


.grid figure {
position: relative;
float: left;
overflow: hidden;
width: 100%;
text-align: center;
cursor: pointer;
}
figure.effect-jazz figcaption::after,
figure.effect-jazz img
{
-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
transition: opacity 0.35s, transform 0.35s;
}

figure.effect-jazz:hover img {
-webkit-transform: scale3d(1.05,1.05,1);
transform: scale3d(1.05,1.05,1);
}

.col_red{
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
 }
.col_light{
color:#abb0bf; 
 }

.bg_red{
  background: linear-gradient(to right, #662D8C , #ED1E79); /* Adjust colors as needed */
 }
.bg_grey{
background: #191a1d;
 }
.bg_dark{
background: #27292d;
 }

hr{
opacity:1;
background-color:#27292d; 
 }
.font_14{
font-size:14px; 
 }
.font_12{
font-size:12px; 
 }

.font_60{
font-size:60px; 
 }

.line {
height: 3px!important;
width: 60px;
background-background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
margin-top:10px;
margin-bottom:10px;
}
.hr_1{
background-background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent; 
 }

/*********************header****************/
#top{
background-color: #191a1d;
border-bottom: 1px solid #27292d; 
padding-top:15px;
padding-bottom:10px;
 }
.top_1m .form-control{
height:36px;
 }
.top_1r ul li a{
background:#28292e;
height:35px;
width:35px;
 }
.top_1r ul li a i{
line-height:35px;
font-size:16px;
 }
.navbar{
padding-top:20px;
padding-bottom:20px;
background-color: #191a0703; 
position: fixed;
top: 0;
width: 100%;
z-index:3;
 }

#header .navbar-brand{
font-size: 1.75rem;
padding:0;
margin:0;
color: white;
 }
 #header .navbar-brand:hover{
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
  }

#header .sticky .navbar-brand:hover{
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
  }

#header .nav-link{
color:#fff;
padding:10px 20px;
font-weight:bold;
font-size:18px;
 }

#header .nav-link:hover{
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
 }

 #header .sticky .navbar-nav .nav-link:hover {
  background: linear-gradient(to right, #662D8C, #ED1E79);;
  -webkit-background-clip: text;
  color: transparent;
}

#header .nav-link:focus{
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
 }
 
#header .sticky .navbar-nav .active{
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
 }

 #header  .active{
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
 }

.drop_1{
min-width:220px;
padding:0;
border-radius:0;
margin-top:21px!important;

background:#191a1d;
 }
.drop_1 a{
padding-top:10px;
padding-bottom:10px;
color: #909090;
border-bottom:1px solid #28292e;
font-weight:bold;
 }
.drop_1 a:hover{
background:#101012;
color:#fff;
 }

.sticky {
position: fixed;
top: 0;
width: 100%;
z-index:3;
padding-top:10px;
padding-bottom:10px;
background-color: #191a1d;
}
.navbar-brand{
  
display:block!important;
margin-left:100px!important; 
 }

.sticky .drop_1{
margin-top:11px!important; 
 }
/*********************header_end****************/


/*********************common****************/
.pages{
margin-top:35px;
}
.pages ul li{
 display:inline-block;
 }
.pages ul li a{
display:block;
color: #999;
 margin: 0 2px;
 width:40px;
 height:40px;
 line-height:40px;
 text-align:center;
 border:1px solid #27292d;
 font-weight:bold; 
 }
 
.pages ul li a:hover{
 background: linear-gradient(to right,  #662D8C , #ED1E79); ;
 border:1px solid #de1002;
 }
.pages ul li a:hover{
 color: #fff!important;
 }
.act{
background:linear-gradient(to right,  #662D8C , #ED1E79)!important;
border:1px solid #de1002!important;
color:#fff!important;
 }

 /* footer social icons */
ul.social-network {
list-style: none;
display: block;
margin-left:0 !important;
padding: 0;
}
ul.social-network li {
display: inline-block;
}

/* footer social icons */
.social-network a.icoRss:hover {
background-color: #F56505;
}
.social-network a.icoFacebook:hover {
background-color:#3B5998;
}
.social-network a.icoTwitter:hover {
background-color:#33ccff;
}
.social-network a.icoGoogle:hover {
background-color:#BD3518;
}
.social-network a.icoVimeo:hover {
background-color:#0590B8;
}
.social-network a.icoLinkedin:hover {
background-color:#007bb7;
}
.social-network a.icoRss:hover i, .social-network a.icoFacebook:hover i, .social-network a.icoTwitter:hover i,
.social-network a.icoGoogle:hover i, .social-network a.icoVimeo:hover i, .social-network a.icoLinkedin:hover i {
color:#fff;
}
a.socialIcon:hover, .socialHoverClass {
color:#44BCDD;
}

.social-circle li a {
display:inline-block;
position:relative;
margin:0 auto 0 auto;
-moz-border-radius:50%;
-webkit-border-radius:50%;
border-radius:50%;
text-align:center;
width: 40px;
height: 40px;
}
.social-circle li i {
margin:0;
line-height:40px;
text-align: center;
}

.social-circle li a:hover i, .triggeredHover {
-moz-transform: rotate(360deg);
-webkit-transform: rotate(360deg);
-ms--transform: rotate(360deg);
transform: rotate(360deg);
-webkit-transition: all 0.2s;
-moz-transition: all 0.2s;
-o-transition: all 0.2s;
-ms-transition: all 0.2s;
transition: all 0.2s;
}
.social-circle i {
color: #fff;
-webkit-transition: all 0.8s;
-moz-transition: all 0.8s;
-o-transition: all 0.8s;
-ms-transition: all 0.8s;
transition: all 0.8s;
}
.form-control:focus{
 box-shadow:none;
 border-color:#3b3c3f;
 background:none;
 }
.form-select:focus{
 box-shadow:none;
 border-color:#27292d; 
 }
.form-control{
border-color:#37393b;
border-radius:0;
color:#999!important;
background:none;
 }
.form-select{
border-color:#27292d;
border-radius:0;
color:#999!important;
 }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#999!important;
  opacity: 1; /* Firefox */
}
.center_o1r {
padding-top: 10px;
}
.popular_2i1lm2 ul li a{
background:#000c;
color:#fff;
width:45px;
height:45px;
line-height:45px;
display:block;
border-radius:50%;
 }
.popular_2i1lm2{
padding-top:95px;
display:none;
 }
.popular_2i1lm:hover .popular_2i1lm2{
display:block;
 }
.carousel-indicators [data-bs-target] {
 background: linear-gradient(to right,  #662D8C , #ED1E79); ;
}
#stream .carousel-indicators{
bottom:-50px; 
 }
#stream .trend_2im2{
padding:50px 15px 0px 15px!important; 
background-color: rgba(0, 0, 0, 0.8);
height:100%;
 }
.contact_2l1i .form-control {
height: 50px;
}
.contact_2l1i .form_text {
 height: 160px;
}

.accordion-item:first-of-type{
border-radius:0; 
 }
.accordion-item {
border:none;
background:#010102;
}
.accordion-button:not(.collapsed) {
border-radius:0;
background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
background-color: #212226;
box-shadow:none;
}
.accordion-button{
box-shadow:none;
font-weight:bold; 
 }
.accordion-item:first-of-type .accordion-button {
border-radius:0;
background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
background-color: #212226;
box-shadow:none;
}
.play2rir .progress {
background-color: #fff;
border-radius:0;
height:4px;
}
.play2rir .progress-bar {
background-background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
}
/*********************common_end****************/

/*********************footer****************/
.footer_m{
background-color: rgba(0, 0, 0, 0.92);
padding-top:30px;
padding-bottom:30px; 
 }
#footer {
background-image: url(../img/3.jpg);
background-position: center;
}
.footer_1i .form-control{
height:36px;
 }
.footer_1i ul li a{
background:#28292e;
height:35px;
width:35px;
 }
.footer_1i ul li a i{
line-height:35px;
font-size:16px;
 }
 
#footer_b{
border-top: 2px solid;
border-image: linear-gradient(to right, #662D8C, #ED1E79) 1;
}
/*********************footer_end****************/



@media screen and (max-width : 767px){
.navbar-collapse{
max-height:300px;
overflow-y:scroll; 
border-top:1px solid #2d2e34;
margin-top:15px;
 }
#header .nav-link{
font-size:28px;
border-bottom:1px solid #2d2e34;
 }
.navbar-light {
box-shadow:none!important;
border:none;
border-radius:0;
 }
.drop_1{
margin-top:0!important; 
 }
.sticky .drop_1{
margin-top:0!important; 
 }
.drop_1 a{
font-size:24px; 
 }
.line{
margin-left:auto;
margin-right:auto; 
 }

#top{
display:none; 
 }
#header .navbar-brand{
display:block;
}
.sticky  .navbar-brand{
margin-right:0!important; 
 }
.trend_1{
text-align:center; 

 }
.trend_1r{
text-align:center!important; 
margin-top:15px; 
 }
.footer_1i h3{
text-align:center; 
 }
.footer_1i h4{
text-align:center; 
 }
.footer_1i{
margin-top:10px;
margin-bottom:10px;
 }
.footer_1i1i img{
height:auto; 
 }
.footer_1i ul{
text-align:center; 
 }
.footer_1r{
margin-top:15px 
 }
.center_o1r {
text-align:center!important; 
 }
.center_o1l {
text-align:center; 
 }
.pages ul li{
 margin-bottom:8px;
 }
.pages {
text-align:center;
 }
.blog_1r{
margin-top:15px  
 }
.blog_1r1  h4{
text-align:center; 
 }
.contact_1i{
margin-top:10px; 
margin-bottom:10px; 
 }
.contact_2r h5{
 text-align:center;
 }
.contact_2l h4{
 text-align:center; 
 }
.contact_2l1i .form-control {
margin-top:15px;
}
.contact_2l1i h6{
 text-align:center;
 }
#stream .trend_2im2 {
display:none; 
 }
 
.trend_1l{
text-align:left;
 }
.trend_1r{
text-align:right!important;
margin-top:0; 
 }
 }


@media (min-width:576px) and (max-width:767px) {

 }
@media (min-width:768px) and (max-width:991px) {
.navbar-expand-md {
flex-wrap:wrap!important;
}
.sticky  .navbar-brand{
margin-right:20px!important; 
 }
.sticky .nav-link{
padding:10px 10px!important; 
 }
.popular_2i1lm1 img{
min-height:240px; 
 }
#stream .trend_2im2 {
display:none; 
 }
.contact_1il span{
font-size:16px!important; 
 }
 }
@media (min-width:871px) and (max-width:991px) {
.sticky  .navbar-brand{
margin-right:40px!important; 
 }

 }
@media (min-width:992px) and (max-width:1200px) {
.navbar-expand-md {
flex-wrap:wrap!important;
}
.sticky  .navbar-brand{
margin-right:60px!important; 
 }
.popular_2i1lm1 img{
min-height:240px; 
 }
#stream .trend_2im1 img{
min-height:200px; 
 }
 }
@media (min-width:1201px) and (max-width:1255px) {

 }


 .trend_2im img {
  height: 250px; 
  width: 100%; 
  object-fit: cover; 
}
/* Custom Modal Styling */
.custom-modal {
background-color: #000;
padding: 30px;
border-radius: 20px;
z-index: 1;
max-width: 90%;
margin: auto;
}

/* Larger Modal for Search */
.modal-lg {
max-width: 900px;  /* Make modal wider */
}

/* Gradient Border */
.custom-modal:before {
content: '';
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: -1;
border-radius: 20px;
padding: 4px;
background: linear-gradient(to right, #662D8C, #ED1E79);
}

.custom-modal:after {
content: '';
position: absolute;
top: 4px;
left: 4px;
right: 4px;
bottom: 4px;
z-index: -1;
background-color: black;
border-radius: 18px;
}

/* Input Group Styles */
.input-group .form-control {
background-color: #000;
border: 1px solid #444;
color: #fff;
padding: 10px;
}

.input-group-btn .btn {
background-color: #ED1E79;  /* Red button */
color: #fff;
padding: 10px 20px;
transition: background 0.3s ease;
}

.input-group-btn .btn:hover {
background-color: #662D8C;  /* Gradient color on hover */
}
#trend, #upcome, #root, .about_pgm, #blog, #contact{
  background-color: #000;
}


.center_home{
  margin-top: -90px;
 }

 .nav-link button{
  margin-top: -10px;
 }

.modal-content{
  --bs-modal-bg: #ffffff00;
}

.slogan{
  color:rgb(153, 151, 151)
}



/* Optional: Focus styles for accessibility */
.custom-checkbox input:focus ~ .checkmark {
  box-shadow: 0 0 3px #de1002;
}

/* Custom Checkbox Styling */
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  position: relative;
  padding-left: 35px; /* Space for the checkbox */
  color: #f4f4f4; /* Ensure text is visible on dark background */
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 25px;
  width: 25px;
  background-color: #e1e2e6; /* Match the dark background */
  border-radius: 4px;
  transition: background 0.3s, border 0.3s;
}

/* When the checkbox is checked, add the gradient background */
.custom-checkbox input:checked ~ .checkmark {
  background: linear-gradient(to right, #662D8C, #ED1E79); /* Using col_red gradient */

}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Hover effects for the checkbox */
.custom-checkbox:hover .checkmark {
  background-color: #eeeff0; /* Slightly lighter on hover */
}

/* Optional: Focus styles for accessibility */
.custom-checkbox input:focus ~ .checkmark {
  box-shadow: 0 0 3px #de1002;
}

/* Password Validations Styling */
.password-validations {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
  font-size: 14px;
  color: white;
}

.password-validations li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.password-validations li i {
  margin-right: 8px;
  font-size: 16px;
}

.password-validations li.valid i {
  color: #28a745; /* Green */
}

.password-validations li.invalid i {
  color: #dc3545; /* Red */
}
/* src/components/EventCard.css */

/* Container for the entire card */
.event-card {
  background-color: #2c2c2c; /* Dark background */
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s;
}

/* Hover effect */
.event-card:hover {
  transform: translateY(-5px);
}

/* Image container */
.event-card-img {
  position: relative;
}

.event-card-img img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
}

/* Overlay icon */
.event-overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff0000; /* Red color for the icon */
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.event-card-img:hover .event-overlay-icon {
  opacity: 1;
}

.event-overlay-icon i {
  font-size: 24px;
}

/* Event details */
.event-card-body {
  color: #ffffff; /* White text */
}

.event-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.event-title a {
  background: linear-gradient(to right,  #662D8C , #ED1E79); /* Adjust colors as needed */
  -webkit-background-clip: text;
  color: transparent;
}

.event-title a:hover {
  text-decoration: underline;
}

.event-summary {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.event-views {
  font-size: 0.8rem;
  color: #cccccc; /* Light gray for views */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .event-card {
    margin-bottom: 1.5rem;
  }
}


/* Existing global styles... */

/* Custom gradient for the navbar-toggler background */
.navbar-toggler {
  box-shadow:none!important;
  background: linear-gradient(to right, #662D8C, #ED1E79); /* Your gradient colors */
  
  border: none; /* Remove default border */
  padding: 0.25rem 0.75rem; /* Adjust padding as needed */
  border-radius: 0.25rem; /* Optional: Add slight rounding */
}

/* Change the navbar-toggler-icon to white */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/ %3E%3C/svg%3E");
}

/* Remove left margin for navbar-brand on mobile screens */
@media screen and (max-width: 767px){
  #header .navbar-brand{
    display: block;
    margin-left: 0!important; /* Remove left margin */
    margin-right: 0!important; /* Ensure no right margin */
}
.navbar-brand{
    margin-left: 0!important; /* Override the default left margin */
}

/* Optional: Adjust logo size if needed */
.navbar-brand img {
    width: 40px; /* Adjust as per your design */
    height: auto;
}

/* Optional: Adjust navbar-toggler size for better touch targets */
.navbar-toggler {
    padding: 0.5rem 0.75rem; /* Increase padding for easier tapping */
}
}


/* Footer Styles */

/* Main Footer */
#footer .footer_m {
  background-color: rgba(0, 0, 0, 0.92);
  padding-top: 30px;
  padding-bottom: 30px;
}

#footer .footer_1i h3 a {
  color: #ffffff;
  font-size: 1.5rem;
  text-decoration: none;
}

#footer .footer_1i h3 a:hover {
  background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
}

#footer .footer_1i p {
  color: #f4f4f4;
  font-size: 1rem;
}

#footer .footer_1i h6 {
  color: #f4f4f4;
}

#footer .footer_1i .col_red {
  background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
}

#footer .footer_1i .form-control {
  background-color: #000;
  border: 1px solid #444;
  color: #fff;
  padding: 10px;
}

#footer .footer_1i .form-control::placeholder {
  color: #999 !important;
}

#footer .footer_1i .input-group {
  display: flex;
}

#footer .footer_1i .input-group .form-control {
  flex: 1;
}

#footer .footer_1i .btn {
  background-color: #ED1E79;
  color: #fff;
  padding: 10px 20px;
  transition: background 0.3s ease;
  border-radius: 0 0.25rem 0.25rem 0; /* Rounded corners on the right */
}

#footer .footer_1i .btn:hover {
  background-color: #662D8C;
}

#footer .social-network.social-circle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  margin: 0;
}

#footer .social-network.social-circle li {
  margin-right: 10px;
}

#footer .social-network.social-circle li a {
  background: #28292e;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s;
}

#footer .social-network.social-circle li a:hover {
  background: linear-gradient(to right, #662D8C, #ED1E79);
}

#footer .social-network.social-circle li a i {
  color: #fff;
  font-size: 16px;
}

/* Bottom Footer */
#footer_b {
  background-color: #191a1d;
  padding-top: 15px;
  padding-bottom: 15px;
}

#footer_b .footer_1l p {
  color: #999;
  font-size: 0.9rem;
}

#footer_b .footer_1r ul li a {
  color: #fff;
  font-size: 16px;
  transition: color 0.3s;
}

#footer_b .footer_1r ul li a:hover {
  background: linear-gradient(to right, #662D8C, #ED1E79);
    -webkit-background-clip: text;
    color: transparent;
}

/* Responsive Footer */
@media screen and (max-width: 767px){
  /* Center-align content in main footer */
  #footer .footer_1i {
      text-align: center;
  }

  /* Center-align social icons */
  #footer .footer_1i .social-network.social-circle {
      justify-content: center;
  }

  /* Center-align bottom footer content */
  #footer_b .footer_1l,
  #footer_b .footer_1r {
      text-align: center;
  }

  #footer_b .footer_1r ul li {
      display: inline-block;
      margin-right: 10px;
  }

  /* Stack newsletter input and button vertically */
  #footer .footer_1i .input-group {
      flex-direction: column;
  }

  #footer .footer_1i .input-group .form-control {
      width: 100%;
      margin-bottom: 10px;
  }

  #footer .footer_1i .btn {
      width: 100%;
      border-radius: 0.25rem; /* Rounded corners */
  }
}

/* Optional: Adjust newsletter input and button on larger screens */
@media screen and (min-width: 768px) and (max-width: 991px){
  #footer .footer_1i .input-group .form-control {
      margin-right: 10px;
  }

  #footer .footer_1i .btn {
      border-radius: 0.25rem; /* Rounded corners */
  }
}

/* Optional: Additional adjustments for very large screens */
@media screen and (min-width: 1200px){
  #footer .footer_1i .form-control {
      max-width: 300px;
  }
}
/* Ensure carousel controls are above event cards */
.carousel-control-prev,
.carousel-control-next {
    z-index: 2; /* Higher than default to ensure visibility */
}

.carousel-item {
    z-index: 1; /* Ensure carousel items are below controls */
}

/* Add padding to carousel-inner for both carousels */
#carouselExampleCaptions1 .carousel-inner,
#carouselExampleCaptions2 .carousel-inner {
    padding-left: 40px; /* Adjust the value as needed */
    padding-right: 40px;
}

/* Reduce padding for smaller screens */
@media screen and (max-width: 767px){
    #carouselExampleCaptions1 .carousel-inner,
    #carouselExampleCaptions2 .carousel-inner {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* Prevent overlay from intercepting clicks */
.trend_2im2 {
    pointer-events: none;
}

.trend_2im2 a {
    pointer-events: auto; /* Allow clicks on links within the overlay */
}
