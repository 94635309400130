/* Loading spinner */
    .loader {
        border: 6px solid #f3f3f3;
        border-top: 6px solid #00867E;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 20px auto;
      }
  
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
  
      /* Success message styling */
      .success-message {
        color: green;
        font-weight: bold;
        text-align: center;
      }
      .button{
        border: 0;
      }

/* Auth Layout Styling */
.auth-layout {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
}

/* Form styles */
.auth-form {
  max-width: 450px;
  margin: 0 auto;
  background-color: #222;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.auth-form-title {
  text-align: center;
  color: #e61c5d;
  margin-bottom: 1.5rem;
}

/* Input field styling */
.auth-input {
  background-color: #333;
  border: 1px solid #444;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.auth-input:focus {
  border-color: #e61c5d;
  box-shadow: 0 0 0 2px rgba(230, 28, 93, 0.25);
}

/* Button styling */
.auth-button {
  background-color: #e61c5d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: #d01953;
}

.auth-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

/* Links */
.auth-link {
  color: #e61c5d;
  text-decoration: none;
  transition: color 0.3s;
}

.auth-link:hover {
  color: #ff4081;
  text-decoration: underline;
}

/* Message alerts */
.auth-message {
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.auth-message.success {
  background-color: #1b5e20;
  color: white;
}

.auth-message.error {
  background-color: #b71c1c;
  color: white;
}

/* Password validation styling */
.password-validations {
  list-style: none;
  padding-left: 5px;
  font-size: 0.8rem;
  margin-top: 5px;
}

.password-validations li {
  margin-bottom: 2px;
}

.password-validations li.valid {
  color: #4CAF50;
}

.password-validations li.invalid {
  color: #F44336;
}
