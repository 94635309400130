/* Existing profile CSS */
.profile-iconn {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #e61c5d;
  background-color: #333;
}

/* Add new CSS for image upload */
.profile-image-upload {
  position: relative;
  display: inline-block;
}

.profile-image-upload .overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 12px;
  padding: 5px;
  text-align: center;
}

.profile-image-upload:hover .overlay {
  opacity: 1;
}
