

/* src/assets/css/Moments.css */
.moment-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .moment-card:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .moment-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .card-body {
    padding: 20px;
    text-align: center;
  }
  
  .moment-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .moment-actions {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .btn-view,
  .btn-download {
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-view {
    background-color: #00867E;
    color: white;
  }
  
  .btn-download {
    background-color: #ff6347;
    color: white;
  }
  
  .btn-view:hover {
    background-color: #006b65;
  }
  
  .btn-download:hover {
    background-color: #e54e3a;
  }
  